import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    alias: ["/login"],
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/register",
    alias: ["/register"],
    name: "register",
    component: () => import("@/views/register.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/forgot-password",
    alias: ["/forgot-password"],
    name: "forgot-password",
    component: () => import("@/views/forgot-password.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/no-workspace",
    alias: ["/no-workspace"],
    name: "no-workspace",
    component: () => import("@/views/no-workspace/no-workspace.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/reset-password",
    alias: ["/reset-password"],
    name: "reset-password",
    component: () => import("@/views/reset-password.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/verify-otp/:type/:email/:rememberMe?",
    alias: ["/verify-otp"],
    name: "verify-email-otp",
    component: () => import("@/components/other/verify-email-otp.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/change-password",
    alias: ["/change-password"],
    name: "change-password",
    component: () => import("@/views/change-password.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
];

const authRoutes = [
  "login",
  "register",
  "forgot-password",
  "reset-password",
  "no-workspace",
  "verify-email-otp",
];
const specialPath = [
  "/canvas/amplitel/digital-twin",
  "/canvas/amplitel/from-above",
  "/canvas/amplitel/los",
  "/canvas/amplitel/side-view",
  "/canvas/amplitel/tower-view",
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});

const userLoggedIn = localStorage.getItem('dronos-token')

router.afterEach((to) => {
  if (!authRoutes.includes(to.name) && !specialPath.includes(to.path) && !userLoggedIn) {
    window.history.replaceState({}, "", "/");
  }
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
